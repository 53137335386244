import { Gender } from '@prisma/client'
import { useFormAction, useNavigation } from '@remix-run/react'
import { clsx, type ClassValue } from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSpinDelay } from 'spin-delay'
import { type SupportedPreset } from '#app/components/PresetImage.tsx'
import { getEnv } from './env.server.ts'

if (!global.ENV) {
  global.ENV = getEnv()
}

export function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message
  }
  console.error('Unable to get error message for error', error)
  return 'Unknown Error'
}

export function cn(...inputs: ClassValue[]) {
  return clsx(inputs)
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get('X-Forwarded-Host') ??
    request.headers.get('host') ??
    new URL(request.url).host
  const protocol = host.includes('localhost') ? 'http' : 'https'
  return `${protocol}://${host}`
}

export function getFullUrlBasedOnEnv(path?: string) {
  return process.env.NODE_ENV === 'development'
    ? `http://localhost:3000${path ?? ''}`
    : `https://www.clubedotennis.com.br${path ?? ''}`
}

export function getReferrerRoute(request: Request) {
  // spelling errors and whatever makes this annoyingly inconsistent
  // in my own testing, `referer` returned the right value, but 🤷‍♂️
  const referrer =
    request.headers.get('referer') ??
    request.headers.get('referrer') ??
    request.referrer
  const domain = getDomainUrl(request)
  if (referrer?.startsWith(domain)) {
    return referrer.slice(domain.length)
  } else {
    return '/'
  }
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const merged = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      merged.set(key, value)
    }
  }
  return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }
  return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
  ...responseInits: Array<ResponseInit | null | undefined>
) {
  let combined: ResponseInit = {}
  for (const responseInit of responseInits) {
    combined = {
      ...responseInit,
      headers: combineHeaders(combined.headers, responseInit?.headers),
    }
  }
  return combined
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
  condition: any,
  message: string | (() => string),
): asserts condition {
  if (!condition) {
    throw new Error(typeof message === 'function' ? message() : message)
  }
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
  condition: any,
  message: string | (() => string),
  responseInit?: ResponseInit,
): asserts condition {
  if (!condition) {
    throw new Response(typeof message === 'function' ? message() : message, {
      status: 400,
      ...responseInit,
    })
  }
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
  const contextualFormAction = useFormAction()
  const navigation = useNavigation()
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  )
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
  formAction,
  formMethod,
  delay = 400,
  minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
  Parameters<typeof useSpinDelay>[1] = {}) {
  const isPending = useIsPending({ formAction, formMethod })
  const delayedIsPending = useSpinDelay(isPending, {
    delay,
    minDuration,
  })
  return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  return (...args: Args) => fns.forEach(fn => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = useState(false)

  function getButtonProps(
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ) {
    const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
      () => setDoubleCheck(false)

    const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
      doubleCheck
        ? undefined
        : e => {
            e.preventDefault()
            setDoubleCheck(true)
          }

    const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] =
      e => {
        if (e.key === 'Escape') {
          setDoubleCheck(false)
        }
      }

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
      onKeyUp: callAll(onKeyUp, props?.onKeyUp),
    }
  }

  return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
  fn: Callback,
  delay: number,
) {
  let timer: ReturnType<typeof setTimeout> | null = null
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

/**
 * Debounce a callback function
 */
export function useDebounce<
  Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  })
  return useMemo(
    () =>
      debounce(
        (...args: Parameters<Callback>) => callbackRef.current(...args),
        delay,
      ),
    [delay],
  )
}

export async function downloadFile(url: string, retries = 0) {
  const MAX_RETRIES = 3
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Failed to fetch image with status ${response.status}`)
    }
    const contentType = response.headers.get('content-type') ?? 'image/jpg'
    const blob = Buffer.from(await response.arrayBuffer())
    return { contentType, blob }
  } catch (e) {
    if (retries > MAX_RETRIES) throw e
    return downloadFile(url, retries + 1)
  }
}

export function obscureEmail(email: string) {
  const [name, domain] = email.split('@')
  return `${name[0]}${new Array(name.length).join('*')}@${domain}`
}

/**
 * Get a Date object 3 days from now
 *
 * @param days the number of days from now
 *
 * @example
 * daysFromNow(3)
 * returns a Date 3 days from now
 *
 */
export function daysFromNow(days: number) {
  const today = new Date()
  const copy = new Date(Number(today))
  copy.setDate(today.getDate() + days)
  return copy
}
const timeAgoFormatter = new Intl.RelativeTimeFormat('pt-BR', {
  numeric: 'auto',
})

const TIME_AGO_DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
]

const oneHour = 60 * 60 * 1000
/**
 * A constant object containing date-related utility functions.
 *
 */
export const time = {
  /**
   * The local date range for today, including midnight and end-of-day, with an offset of 3 hours (Brazilian timezone).
   *
   * @property {Function} local.format - Adjusts a given date to account for the 3-hour offset.
   */
  local: {
    dateFromUtc: (date: Date) =>
      new Date(`${time.toDateInput(date)}T00:00:00-03:00`),

    beginningOfDay: (date?: Date) => {
      const todayOrDate = date ?? new Date()
      const inUTCTimezone = todayOrDate.getTimezoneOffset() == 0

      const offset = inUTCTimezone ? 3 * oneHour : 0
      const hours = inUTCTimezone ? 3 : 0

      return new Date(
        new Date(todayOrDate.getTime() - offset).setHours(hours, 0, 0, 0),
      )
    },
    endOfDay: (date?: Date) => {
      const todayOrDate = date ?? new Date()
      const beginningOfDay = time.local.beginningOfDay(todayOrDate)

      return new Date(beginningOfDay.getTime() + 24 * oneHour - 1)
    },
    parseWithHourString: (date: Date, hour: string): Date => {
      const formatedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hour} GMT-3`

      return new Date(formatedDate)
    },
  },
  /**
   * Compares two dates and returns true if they are equal.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the dates are equal, false otherwise.
   * @example
   * today.isEqual(new Date(), new Date())
   *
   */
  isEqual: (current: Date, target: Date): boolean =>
    current.getTime() === target.getTime(),

  toDateInput: (date: Date): string => {
    return date.toISOString().split('T')[0]
  },
  /**
   * Compares two dates and returns true if the first date is before the second date.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the first date is before the second date, false otherwise.
   * @example
   * today.isBefore(new Date(), new Date())
   */
  isBefore: (current: Date, target: Date): boolean =>
    current.getTime() < target.getTime(),

  /**
   * Compares two dates and returns true if the first date is after the second date.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the first date is after the second date, false otherwise.
   * @example
   * today.isAfter(new Date(), new Date())
   */
  isAfter: (current: Date, target: Date): boolean =>
    current.getTime() > target.getTime(),

  /**
   * Compares two dates and returns true if the first date is greater than the second date.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the first date is greater than the second date, false otherwise.
   * @example
   * today.isGreaterThan(new Date(), new Date())
   */
  isGreaterThan: (current: Date, target: Date): boolean =>
    current.getTime() > target.getTime(),

  /**
   * Compares two dates and returns true if the first date is greater than or equal to the second date.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the first date is greater than or equal to the second date, false otherwise.
   * @example
   * today.isGreaterThanOrEqualTo(new Date(), new Date())
   */
  isGreaterThanOrEqualTo: (current: Date, target: Date): boolean =>
    current.getTime() >= target.getTime(),

  /**
   * Compares two dates and returns true if the first date is less than the second date.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the first date is less than the second date, false otherwise.
   */
  isLessThan: (current: Date, target: Date): boolean =>
    current.getTime() < target.getTime(),

  /**
   * Compares two dates and returns true if the first date is less than or equal to the second date.
   *
   * @param {Date} current - The first date.
   * @param {Date} target - The second date.
   * @returns {boolean} True if the first date is less than or equal to the second date, false otherwise.
   * @example
   * today.isLessThanOrEqualTo(new Date(), new Date())
   */
  isLessThanOrEqualTo: (current: Date, target: Date): boolean =>
    current.getTime() <= target.getTime(),

  /**
   * Checks if a given date falls within a specified range.
   *
   * @param {Date} start - The start of the range.
   * @param {Date} end - The end of the range.
   * @param {Date} target - The date to check.
   * @returns {boolean} True if the date is within the range, false otherwise.
   * @example
   * today.isBetween(new Date(), new Date(), new Date())
   */
  isBetween: (start: Date, end: Date, target: Date): boolean => {
    return (
      target.getTime() >= start.getTime() && target.getTime() <= end.getTime()
    )
  },

  /**
   * Returns the difference between two dates in seconds, minutes, hours, days, months, and years.
   *
   * @param {Date} target
   * @param {Date} current
   * @returns {Object} An object containing the difference in seconds, minutes, hours, days, months, and years.
   * @example
   * today.remains(new Date(), new Date())
   * {
   *  seconds: 0,
   *  minutes: 0,
   *  hours: 0,
   *  days: 0,
   *  months: 0,
   *  years: 0
   * }
   */
  remains: (
    target: Date,
    current: Date,
  ): {
    seconds: number | null
    minutes: number | null
    hours: number | null
    days: number | null
    months: number | null
    years: number | null
  } => {
    const delta = target.getTime() - current.getTime()
    if (delta < 0)
      return {
        seconds: null,
        minutes: null,
        hours: null,
        days: null,
        months: null,
        years: null,
      }

    const seconds = Math.floor((delta / 1000) % 60)
    const minutes = Math.floor((delta / 1000 / 60) % 60)
    const hours = Math.floor((delta / (1000 * 60 * 60)) % 24)
    let days = Math.floor(delta / (1000 * 60 * 60 * 24))
    let months = 0
    let years = 0

    if (days >= 365) {
      years = Math.floor(days / 365)
      days %= 365
    }

    if (days >= 30) {
      months = Math.floor(days / 30)
      days %= 30
    }

    return { seconds, minutes, hours, days, months, years }
  },
  hoursRemaining: (current: Date, target: Date): number => {
    const timeDiff = Math.abs(target.getTime() - current.getTime())
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60))

    if (current > target) {
      return 0
    }

    return hoursDiff
  },

  formatTimeAgo: (date: Date): string | undefined => {
    let duration = (date.getTime() - new Date().getTime()) / 1000

    for (let i = 0; i < TIME_AGO_DIVISIONS.length; i++) {
      const division = TIME_AGO_DIVISIONS[i]
      if (Math.abs(duration) < division.amount) {
        return timeAgoFormatter.format(
          Math.round(duration),
          division.name as Intl.RelativeTimeFormatUnit,
        )
      }
      duration /= division.amount
    }
  },
}

export const valueToTrueFalseOrNull = (value: any) => {
  if (value === null || value === undefined) return null
  return value ? 'true' : 'false'
}

export function pluralize(count: number, single: string, multiple: string) {
  if (count === 1) return single
  return multiple
}

export function genderize(gender: Gender | null, male: string, female: string) {
  return gender == Gender.Female ? female : male
}

export function capitalizeFirstLetter(str: string): string {
  return `${str[0].toUpperCase()}${str.slice(1)}`
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export function getImageProxyUrl(
  preset: SupportedPreset | SupportedPreset[],
  src: string,
) {
  return `${ENV.IMGPROXY_URL}/public/${Array(preset).join(':')}/${src}`
}

export function withHttps(link: string) {
  return link.indexOf('://') === -1 && link.indexOf('mailto:') === -1
    ? 'https://' + link
    : link
}

export function buildS3EncodedURL(path: string) {
  return btoa(`s3://${process.env.STORAGE_BUCKET}/${path}`)
}

export function arrayToLocalizedListOfNames(names: string[]): string {
  if (names.length === 0) return ''
  if (names.length === 1) return names[0]

  const lastName = names.pop()
  return `${names.join(', ')} e ${lastName}`
}

export function splitArrayIntoGroups<T>(array: T[], groupSize: number): T[][] {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += groupSize) {
    result.push(array.slice(i, i + groupSize))
  }
  return result
}
